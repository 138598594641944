import './App.css';

function App() {
  return (
    <div className="App">
      <header className="grape-header">
        <div className="bg-green bg-opacity-35 py-5 px-2 rounded">
          <h1 class="text-9xl font-bold">
            Grape Jelly On Sol
          </h1>
        </div>

        <div class="mt-7 flex justify-center space-x-4"> 
          <div class="center">
            <a href="https://t.me/grapejellyonsol">
            <button class="bg-blue-400 hover:bg-black py-1 px-3 rounded">Telegram</button>
            </a>
          </div>
          <div class="center">
            <a href="https://x.com/GrapeJellyOnSol">
            <button class="bg-blue-400 hover:bg-black py-1 px-3 rounded">X</button>
            </a>
          </div>
          <div class="center">
            <a href="https://dexscreener.com/solana/FLqCCLnYN4pHNJknSoWPbkedmNwb7CT8RZmarETTASrV?id=638e8652">
            <button class="bg-blue-400 hover:bg-black py-1 px-3 rounded">Chart</button>
            </a>
          </div>
        </div>
        <br></br>
        <div className="trans_box">
        <p class="text-white">
          Coin Address: FLqCCLnYN4pHNJknSoWPbkedmNwb7CT8RZmarETTASrV </p>
        </div>
      </header>
    </div>
  );
}

export default App;
